// * React components
import React, { Suspense, lazy } from "react";
// * Third party components
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contactus from "./Components/InnerPages/ContactUs/Contactus";

// * Pages;

const MainPage = lazy(() => import("./Components/InnerPages/ContactUs/Contactus"));
export const AppRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route path="/" element={<MainPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};
